<script setup lang="ts">
import {
	BaseTextField,
	BaseIcon,
	BaseButton,
	BaseTooltip,
} from '@legacy-studio/library';
import { useEventListener } from '@vueuse/core';
import { useAssets } from '../infrastructure/store';
import {
	getAssetsGroups,
	filterAssetsBySearch,
	filterAssetsByGroup,
} from '../utils';
import { bus } from '../infrastructure/bus';
import { useGetCurrentUserAssetPercent } from '../core/application';
import type { AssetItem } from '../types';
import AssetsListItem from './Item.vue';
import { useIsUserVip } from '@/src/features/user';
import { UiDarkBBButton } from '~/src/shared/ui';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const isVip = useIsUserVip();
const assets = useAssets();

const search = ref('');
const activeType = ref('');

const typeButtons = computed(() => getAssetsGroups(assets.value));
const filteredAssets = computed(() =>
	filterAssetsBySearch(
		filterAssetsByGroup(assets.value, activeType.value),
		search.value,
	),
);

const triggerSelectAsset = bus.useTriggerSelectAsset();
const getCurrentUserAssetPercent = useGetCurrentUserAssetPercent();
const drawerStore = useSidebarStore();

const onSelectAsset = (asset: AssetItem) => {
	triggerSelectAsset(asset);
	drawerStore.closeDrawer();
};

const scrollContainer = ref<HTMLElement | null>(null);

const isDragging = ref(false);
const startX = ref(0);
const scrollLeft = ref(0);

const onPointerDown = (event: PointerEvent) => {
	if (!scrollContainer.value) return;

	isDragging.value = true;
	scrollContainer.value.classList.add('cursor-grabbing');
	scrollContainer.value.setPointerCapture(event.pointerId);
	startX.value = event.pageX - scrollContainer.value.offsetLeft;
	scrollLeft.value = scrollContainer.value.scrollLeft;

	event.preventDefault();
};

const onPointerMove = (event: PointerEvent) => {
	if (!isDragging.value || !scrollContainer.value) return;

	const x = event.pageX - scrollContainer.value.offsetLeft;
	const walk = x - startX.value;
	scrollContainer.value.scrollLeft = scrollLeft.value - walk;
};

const onPointerUp = (event: PointerEvent) => {
	if (!isDragging.value || !scrollContainer.value) return;

	isDragging.value = false;
	scrollContainer.value.classList.remove('cursor-grabbing');
	scrollContainer.value.releasePointerCapture(event.pointerId);
};
const onWheel = (event: WheelEvent) => {
	if (event.deltaY !== 0) {
		event.preventDefault();
		scrollContainer.value?.scrollBy({
			left: event.deltaY,
			behavior: 'smooth',
		});
	}
};
useEventListener(scrollContainer, 'pointerdown', onPointerDown);
useEventListener(scrollContainer, 'pointermove', onPointerMove);
useEventListener(scrollContainer, 'pointerup', onPointerUp);
useEventListener(scrollContainer, 'wheel', onWheel);
</script>

<template>
	<div>
		<BaseTextField
			v-model="search"
			placeholder="Поиск по активам"
			left-icon-style="base--fill--search"
			size="regular"
		/>

		<div
			ref="scrollContainer"
			class="flex gap-4 overflow-auto whitespace-nowrap py-6 px-4 -mx-4 scrollbar-hide cursor-grab"
		>
			<template
				v-for="typeButton in typeButtons"
				:key="typeButton"
			>
				<BaseButton
					v-if="typeButton !== activeType"
					color="alternative-dark"
					size="sm"
					outline
					:button-text="typeButton"
					class="!rounded-full"
					@click="activeType = typeButton"
				/>

				<UiDarkBBButton
					v-else
					color="alternative-dark"
					size="sm"
					:button-text="typeButton"
					class="!rounded-full"
					@click="activeType = typeButton"
				/>
			</template>
		</div>

		<div class="flex flex-col">
			<div
				class="flex items-center gap-[14px] text-xs font-normal justify-between text-gray-500 border-b border-gray-600 pb-3"
			>
				<span>Активы</span>

				<div class="flex gap-2 items-center">
					<span>Доходность</span>

					<span
						v-if="!isVip"
						class="flex gap-2 items-center justify-end w-[68px]"
					>
						<span>VIP</span>

						<BaseTooltip
							title=""
							direction="right"
						>
							<template #default>
								<BaseIcon
									name="base--fill--question-mark-circle"
									class="text-[16px] text-gray-500"
								/>
							</template>

							<template #description>
								Повышенная доходность по активам зависит от типа вашего
								<br>
								тарифа. Поменять тариф можно в разделе “Тарифы”
							</template>
						</BaseTooltip>
					</span>
				</div>
			</div>

			<div class="flex flex-col">
				<div
					v-for="item in filteredAssets"
					:key="item.code"
					class="flex items-center gap-[14px] justify-between py-3 border-b border-gray-600 cursor-pointer"
					@click="onSelectAsset(item)"
				>
					<div class="flex items-center gap-2">
						<AssetsListItem :asset="item" />
					</div>

					<div class="flex items-center gap-8">
						<button
							class="rounded-lg ring-1 ring-green-400 hover:bg-green-900 px-1 py-0.5 text-xs font-medium text-green-400"
							type="button"
						>
							{{ `${getCurrentUserAssetPercent(item)} %` }}
						</button>

						<div
							v-if="!isVip"
							class="rounded-lg ring-1 ring-gray-400 px-1 py-0.5 text-xs font-medium text-gray-500 flex gap-0.5 items-center"
						>
							<span>
								{{ `${item.percents.vip} %` }}
							</span>

							<BaseIcon
								name="base--fill--lock-closed"
								class="text-[16px] text-gray-500"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
</style>
