import type { AssetsList } from './types';

export const getAssetsGroups = (assetsList: AssetsList) => {
	return [...new Set(assetsList.map(asset => asset.type))];
};

export const filterAssetsByGroup = (
	assetsList: AssetsList,
	group: string,
): AssetsList => {
	if (!group) {
		return assetsList;
	}
	return assetsList.filter(asset => asset.type === group);
};

export const filterAssetsBySearch = (
	assetsList: AssetsList,
	search: string,
): AssetsList => {
	if (!search) {
		return assetsList;
	}
	return assetsList.filter(asset =>
		asset.name.toLowerCase().includes(search.toLowerCase()),
	);
};
